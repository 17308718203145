.paymentTable {
  font-size: 0.85em;
}

.paymentFilterSelect {
  font-size: 0.9em;
  padding-bottom: 0;
  padding-left: 4px;
  font-weight: bold;
}

.pagination {
  justify-content: center;
}

.paymentDetails{
  font-size: 0.9em;
}
