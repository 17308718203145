@import './utils/variables';

.App {
  min-height: 100vh;
}

.app-loader {
  .app-spinner {
    margin-top: 30vh;
  }
}

.nibi-btn {
  background-color: $secondaryColor;
  color: #FFF;
  border: none;

  &:not(:disabled):not(.disabled):active, &:hover, &:focus {
    background-color: $secondaryColor;
    color: $primaryColor;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(242, 198, 0, 0.5);
  }

  &:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.2rem rgba(242, 198, 0, 0.5);
  }

}

.detail-info-component {

  .header-info {
    display: flex;
    justify-content: center;
    margin-left: 30px;
    flex-flow: column;

    p {
      margin-bottom: 10px;
    }
  }

  img {
    width: 200px;
    height: 200px;
    border-radius: 100%;
  }

  .switch-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 25px 0;
    width: fit-content;

    span {
      font-size: 1.25rem;
      margin-right: 10px;
    }
  }

  .form-buttons {
    text-align: center;

    .btn {
      margin: 20px 10px;
      width: 170px;
    }
  }

  .switch-col {
    display: flex;
    justify-content: center;
  }
}

.table-info-component {
  td {
    .btn {
      display: block;
      margin: 0 auto;
    }
  }

  .title {
    margin-bottom: 15px;
  }

  .header {
    margin-bottom: 20px;
    text-align: center;
  }
}

.not-found-error {
  text-align: center;
  margin: 100px 20px;
}

.invalid-input-value {
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #DC3545;
  display: block;
}

.user-detail-options {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}
