.login-component {

  margin-top: 50px;
  margin-bottom: 50px;

  .login-form {
    padding: 20px 30px;
    border-radius: 20px;

    -webkit-box-shadow: 5px 3px 15px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 5px 3px 15px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 5px 3px 15px 0px rgba(0, 0, 0, 0.3);

    .nibi-btn {
      display: block;
      margin: 0 auto;
      width: 50%;
    }
  }

  .fb-login-btn {
    background-color: #4C69BA;
    font-family: Helvetica, sans-serif;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    color: #FFF;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    text-decoration: none;
    transition: background-color .3s ,border-color .3s;
    background-color: #4C69BA;
    border: none;
    width: 100%;
    height: 47px;
    border-radius: 2px;
    margin-bottom: 10px;
    padding-left: 20px;
    text-align: left;
  
    i {
      margin-right: 25px;
    }
  }
  
  .google-login-btn {
    background-color: rgb(255, 255, 255);
    display: inline-flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.54);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
    padding: 0px; border-radius: 2px;
    border: 1px solid transparent;
    font-size: 14px; font-weight: 500;
    font-family: Roboto, sans-serif;
    width: 100%;
    margin-bottom: 10px;
  
    &:hover {
      opacity: 0.9;
      cursor: pointer;
    }
  
    &:disabled {
      background-color: rgb(255, 255, 255);
      display: inline-flex;
      align-items: center;
      color: rgba(0, 0, 0, 0.54);
      box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
      padding: 0px;
      border-radius: 2px;
      border: 1px solid transparent;
      font-size: 14px;
      font-weight: 500;
      font-family: Roboto, sans-serif;
      opacity: 0.6;
    }
  
    .logo {
      margin-right: 10px;
      background: rgb(255, 255, 255);
      padding: 10px;
      border-radius: 2px;
    }
  
    .text {
      padding: 10px 10px 10px 0px;
      font-weight: 500;
    }
  }

  .signup-separador {

    display: flex;
    align-items: center;
  
    .line {
      height: 1px;
      width: 47%;
      background-color: #E4E4E4;
    }
  
    .text {
      width: 6%;
      text-align: center;
      color: #E4E4E4;
    }
  }

  .signup-error {
    width: 100%;
    background-color: #FFBABA;
    color: #D8000C;
    text-align: center;
    font-size: 0.7em;
    padding: 5px;
  }
  
}
